import { useContext } from "react";
import { ConfigContext } from "../Context/ConfigContext";

const AddToGarage = (CarIfo) => {
    var cars = localStorage.getItem('cars');
    if(cars){
        cars = JSON.parse(cars);
    }else{
        cars = [];
    }
    ///// Reset Selection /////////
    CarIfo.selected = 1;
    for(var i = 0; i < cars.length; i++){
        cars[i].selected = 0;
    }
    
    // Check if there are already 5 cars
    if (cars.length >= 5) {
        cars.shift(); // Remove the oldest car
    }
    
    cars.push(CarIfo);
    
    localStorage.setItem('cars', JSON.stringify(cars));
}

const GetGarages = () => {
    
    var cars = localStorage.getItem('cars');

    if(cars !== ''){
        cars = JSON.parse(cars);
    }else{
        cars = [];
    }
    
    return cars;
}
const SelectCarGarage = async(index) => {
    
    var cars = localStorage.getItem('cars');
    if(cars){
        cars = JSON.parse(cars);
    }else{
        cars = [];
    }
    
    for(var i = 0; i < cars.length; i++){
        if(index === i){
            cars[i].selected = 1;
        }else{
            cars[i].selected = 0;
        }
    }
   
   
    localStorage.setItem('cars', JSON.stringify(cars)); 
    return cars;
}

const RemoveCarGarage = async(index) => {
    
    var cars = localStorage.getItem('cars');
    if(cars){
        cars = JSON.parse(cars);
    }else{
        cars = [];
    }
    
    cars.splice(index, 1);
   
   
    localStorage.setItem('cars', JSON.stringify(cars)); 
    return cars;
}
const ClearGarage = async() => {
   
    localStorage.setItem('cars', '');
    return true;
}
export {AddToGarage, GetGarages, ClearGarage, SelectCarGarage, RemoveCarGarage}