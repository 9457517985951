import React, { useContext, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { CartProcess, GetCarts } from "../../Helper/Carts";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import { ConfigContext } from "../../Context/ConfigContext";
import Swal from "sweetalert2";
import withRouter from "../../Routes/withRouter";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import Invoice from "../../Components/Invoice/Invoice";
import { jsPDF } from "jspdf";
import { useTranslation, withTranslation } from 'react-i18next';
import { ToastContainer, toast } from "react-toastify";
import InvoicePDF from "../../Components/Invoice/InvoicePDF";
import { FaExclamationTriangle } from 'react-icons/fa';
const Failure = () => {
    const { t } = useTranslation();
    const {order_uid} = useParams();
    const { apiURL, token, lang } = useContext(ConfigContext);
    const [orderInfo, setOrderInfo] = useState([]);
    const [address, setAddress] = useState([]);
    const [details, setDetails] = useState([]);
    const [items, setItems] = useState([]);
    const [success, setSuccess] = useState(true);

    useEffect(() => {
       
    }, []);


   
    const dir = localStorage.getItem('dir');
    return (
        <main className="main" dir={dir}>
            <div className="container" style={{ textAlign: 'center', padding: '50px' }}>
                <FaExclamationTriangle size={50} color="red" />
                <h1 style={{ margin: '20px 0' }}>{t('paymentFailed')}</h1>
                <div>
                    <button 
                        onClick={() => window.location.reload()} 
                        style={{ margin: '10px', padding: '10px 20px', backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                    >
                        {t('tryAgain')}
                    </button>
                    <button 
                        onClick={() => window.location.href = '/'} 
                        style={{ margin: '10px', padding: '10px 20px', backgroundColor: '#2196F3', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                    >
                        {t('goHome')}
                    </button>
                </div>
            </div>
        </main>
    );
};

export default Failure;