import { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import { NavLink } from "react-router-dom";
import { AddToGarage } from "../../Helper/MyCars";
import axios from 'axios';
import { useTranslation } from "react-i18next";
const CarSelectionSection = ({ base_url, brand, department, make, year, model, engine, fuel }) => {
    const { gdcURL, setContextPCID, setContextMSID } = useContext(ConfigContext);
    const { t } = useTranslation();
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [series, setSeries] = useState([]);
    const locale = localStorage.getItem('lang');
    const dir = localStorage.getItem('dir');
    const GetCarInfo = async () => {
        var response = await axios.get(`${gdcURL}/Cars/GetCarInfo?pc_id=${localStorage.getItem('pc_id')}&lang=${locale}`);
        var data = response.data;

        localStorage.setItem('car_info', JSON.stringify(data.data));
        setContextPCID(data.data[0].PC_ID)
        setContextMSID(data.data[0].PC_MS_ID)
        return data.data[0];

    }
    useEffect(() => {
        fetch(`${gdcURL}/Cars/GetMakes`)
            .then((res) => res.json())
            .then((data) => {
                setMakes(data.data);
            })
    }, []);
    useEffect(() => {
        if (year) {
            var mfa_id = make.split('-')[0];
            if (year && mfa_id) {
                fetch(`${gdcURL}/Cars/GetModels?mfa_id=${mfa_id}&year=${year}`)
                    .then((res) => res.json())
                    .then((data) => {
                        setModels(data.data);

                    })
            }

        }
    }, [year]);



    useEffect(() => {

        if (model) {

            var ms_id = model.split('-')[0];
            if (ms_id) {
                fetch(`${gdcURL}/Cars/GetSeries?ms_id=${ms_id}`)
                    .then((res) => res.json())
                    .then((data) => {
                        var seriess = filterByYear(data.data, Number(year));
                        setSeries(seriess);

                        // createEngineList(seriess);
                    })
            }
        }


    }, [model]);
    function filterByYear(vehicles, selectedYear) {
        var filteredVehicles = [];
        for (var i = 0; i < vehicles.length; i++) {
            var vehicle = vehicles[i];
            const ciFromYear = new Date(vehicle.PCS_CI_FROM).getUTCFullYear();
            const ciToYear = vehicle.PCS_CI_TO ? new Date(vehicle.PCS_CI_TO).getUTCFullYear() : new Date().getUTCFullYear();


            if (ciFromYear <= selectedYear && ciToYear >= selectedYear) {
                filteredVehicles.push(vehicle);
            }
        }

        return filteredVehicles;
    }


    const SaveCar = async (pc_id) => {

        var ms_id = model.split('-')[0];
        if (pc_id && ms_id) {
            localStorage.setItem('pc_id', pc_id.toString());
            localStorage.setItem('ms_id', ms_id);
            setContextPCID(pc_id);
            setContextMSID(ms_id);

            var car = await GetCarInfo();
            AddToGarage(car);
        }
        window.location.reload();

    }

    return <div dir={dir}>
        {!make &&
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                        {t('for_top_car_brands', { brand: brand, department: department })}
                    </h2>
                </div>
                {
                    makes && makes.map((item) => {
                        return (
                            <div className="m-1" style={{ backgroundColor: '#EEE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <NavLink onClick={() => window.scrollTo(0, 0)} to={`${base_url}/${item.MFA_ID}-${item.label}`}>
                                    <svg height="40" viewBox="0 0 100 40" >

                                        <text
                                            x="50%"
                                            y="50%"
                                            dominantBaseline="middle"
                                            textAnchor="middle"
                                            fontSize={`${Math.max(10, 20 - (item.label?item.label.length:10))}px`}
                                            fontWeight="bold"
                                        >
                                            {item.label}
                                        </text>
                                    </svg>
                                </NavLink>

                            </div>
                        )
                    })
                }</div>
        }
        {/* 
//////////////// Years ///////// */}
        {!year &&
            <div className="row justify-content-center">
                {
                    make && <div className="col-md-12">
                        <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                            {t('for_top_car_years', { brand: brand, department: department })}
                        </h2>
                    </div>
                }
                {
                    make && Array.from({ length: new Date().getFullYear() - 1990 + 1 }, (_, index) => {
                        const year = new Date().getFullYear() - index;
                        return (
                            <div key={year} className="m-1" style={{ backgroundColor: '#EEE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <NavLink onClick={() => window.scrollTo(0, 0)} to={`${base_url}/${make}/${year}`}>
                                    <svg height="40" viewBox="0 0 100 40">
                                        <text
                                            x="50%"
                                            y="50%"
                                            dominantBaseline="middle"
                                            textAnchor="middle"
                                            fontSize="20px"
                                            fontWeight="bold"
                                        >
                                            {year}
                                        </text>
                                    </svg>
                                </NavLink>
                            </div>
                        )
                    }).reverse()
                }
            </div>
        }

        {/* ////////////////////////// MODELS //////////////////////////////// */}
        {!model &&
            <div className="row justify-content-center">
                {
                    models && models.length > 0 && <div className="col-md-12">
                        <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                            {t('for_top_car_models', { brand: brand, department: department })}
                        </h2>
                    </div>
                }

                {
                    models && models.map((item) => {
                        return (
                            <div className="m-1" style={{ backgroundColor: '#FFF', border: '1px solid #EEE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <NavLink onClick={() => window.scrollTo(0, 0)} to={`${base_url}/${make}/${year}/${item.MS_ID}-${item.label}`}>

                                    <table style={{ textAlign: 'center' }}>
                                        <tr>
                                            <td style={{ height: '100px', width: '120px' }}>
                                                <center>
                                                    <img
                                                        width={100}
                                                        src={`${gdcURL}/car-ms-images/${item.MS_ID}`}
                                                        onError={(e) => {
                                                            // e.target.style.display = 'none';
                                                            // e.target.nextSibling.style.display = 'block';
                                                        }}
                                                    />
                                                </center>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {item.label}
                                            </td>
                                        </tr>
                                    </table>
                                    {/* <img 
                                    width={100} 
                                    src={`${gdcURL}/car-ms-images/${item.MS_ID}`}
                                    onError={(e) => {
                                        e.target.style.display = 'none';
                                        e.target.nextSibling.style.display = 'block';
                                    }}
                                />
                                <svg
                                    height="40"
                                    viewBox="0 0 100 40"
                                    style={{ display: 'none' }}
                                >
                                    <text
                                        x="50%"
                                        y="50%"
                                        dominantBaseline="middle"
                                        textAnchor="middle"
                                        fontSize={`${Math.max(8, 10 - (item.label.length))}px`}
                                        fontWeight="bold"
                                    >
                                        {item.label}
                                    </text>
                                </svg> */}
                                </NavLink>
                            </div>
                        )
                    })
                }
            </div>
        }



        {/* /////////////////////////////// SERIES ////////////////////////////// */}
        <div className="row justify-content-center">
            {
                series && series.length > 0 && <div className="col-md-12">
                    <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                        {t('for_top_car_engines', { brand: brand, department: department })}
                    </h2>
                </div>
            }
            {
                series && series.map((item) => {
                    return (
                        <div className="m-1" style={{ backgroundColor: '#EEE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <NavLink onClick={() => SaveCar(item.PC_ID)} to={`${base_url}/${make}/${year}/${model}/${item.PC_ID}-${item.label}`}>
                                <svg
                                    height="50"
                                    viewBox="0 0 100 50"
                                >
                                    <text
                                        x="50%"
                                        y="35%"
                                        dominantBaseline="middle"
                                        textAnchor="middle"
                                        fontSize={`${Math.max(15, 10 - (item.PC_FUEL_TYPE.length))}px`}
                                        fontWeight="bold"
                                    >
                                        {item.PC_FUEL_TYPE}
                                    </text>
                                    <text
                                        x="50%"
                                        y="65%"
                                        dominantBaseline="middle"
                                        textAnchor="middle"
                                        fontSize={`${Math.max(9, 10 - (item.label?item.label.length:10))}px`}
                                        fontWeight="bold"
                                    >
                                        {item.label}
                                    </text>
                                </svg>
                            </NavLink>
                        </div>
                    )
                })
            }
        </div>
    </div>


}
export default CarSelectionSection;