import React , { Component, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { ConfigContext } from '../../Context/ConfigContext';
import { debounce } from '@mui/material';
import slugify from 'slugify';
import Swal from 'sweetalert2';

const SearchBar = () =>{
  const [isHovered, setIsHovered] = useState(false);

      const { t } = useTranslation();
      const [keyword, setKeyword] = useState('');
      const [searchResDisplay, setSearchResDisplay] = useState(false);
      const [SearchResults, setSearchResults] = useState([]);
      const navigate = useNavigate();
      const modalRef = useRef(null);
      const [loading, setLoading] = useState(false);
      const SearchNow = () => {
        navigate(`/Search-Parts/${btoa(keyword)}`)
      }
      const showModal = () => {
        const modal = new window.bootstrap.Modal(modalRef.current);
        modal.show();
      };
      const {gdcURL} = useContext(ConfigContext);
      useEffect(() => {
        if(keyword !== ''){
          setLoading(true);
          fetch(
          `${gdcURL}/Gen/Search?keyword=${keyword.trim().replace(/[^a-zA-Z0-9\s]/g, '')}&pc_id=${localStorage.getItem("pc_id")}&lang=${lang}`
        )
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          const docs = data.response.docs;
          const uniqueDocs = Array.from(new Map(docs.map(doc => [doc.SEARCH_TITLE.join('|'), doc])).values());

        

          setSearchResults(uniqueDocs);
          // if(uniqueDocs.length> 0){
          if(keyword && keyword !== "" && keyword.length > 0){
            setSearchResDisplay(true);
          }else{
            setSearchResDisplay(false);
          }
        });
        }
        
          
      }, [keyword]);
      const lang = localStorage.getItem('lang');
      const dir = localStorage.getItem('dir');

      // const fetchSuggestions = useCallback(
      //   debounce(async (query) => {
      //     if (query.length > 2) { // Trigger suggestions for input longer than 2 characters
      //       try {
      //         fetch(
      //           `${gdcURL}/Gen/Search?keyword=${query}&pc_id=${localStorage.getItem("pc_id")}&lang=${lang}`
      //         )
      //         .then((res) => res.json())
      //         .then((data) => {
      //           setSearchResults(data.response.docs);
      //         });
      //       } catch (error) {
      //         console.error('Error fetching suggestions:', error);
      //       }
      //     } else {
      //       setSearchResults([]);
      //     }
      //   }, 1000), // Delay in milliseconds
      //   []
      // );
      
      const handleChange = (e) => {
        const value = e.target.value;
        setKeyword(value);
        // fetchSuggestions(value);
      };
      const navigateHandle = (part) => {
        console.log("coming", part)
        setSearchResDisplay(false);
        if(part.SEARCH_TYPE === 'CATEGORY'){
          navigate(`/Spare-Parts/${part.SEARCH_URL}`);
        }else if(part.SEARCH_TYPE && part.SEARCH_TYPE === 'IAMNumber') {
          
          navigate(`/Spare-Parts/Details/${part.SEARCH_URL}`);
        }else if(part.SEARCH_TYPE && part.SEARCH_TYPE === 'OENumber') {
          
          navigate(`/OE-Spare-Parts/Details/${part.SEARCH_URL}`);
        }else if(part.SEARCH_TYPE && part.SEARCH_TYPE === 'PRODUCTS') { 
          navigate(part.SEARCH_URL)
        }
        
      }
      const onNavigateBy = () => {
        if(!loading && SearchResults.length === 0){
          Swal.fire({
            title: t('error'),
            text: t('no_result'),
            icon: "error",
            confirmButtonText: "Okay",
          });
        }else{
          setSearchResDisplay(false);
          if(SearchResults.length > 0){
            var part = SearchResults[0];
            document.getElementById('keyword_input').value = part.SEARCH_TITLE;
            navigateHandle(part);
          }
        }
        
        
      }
      
      const divRef = useRef(null);
      const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
          // Clicked outside the div, handle the logic here
          setSearchResDisplay(false);
         
        }
      };
    
      useEffect(() => {
        // Add event listener on mount
        document.addEventListener('mousedown', handleClickOutside);
    
        // Cleanup event listener on unmount
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
      const handleKeyDown = (event) => {
        
        if (event.key === 'Enter') {
          onNavigateBy();
        }
      };
      
      return (
        <>
        
        <div className="modal fade" style={{textAlign: lang === 'ar'?'right': 'left'}} dir={dir} ref={modalRef} id="searchExampleModal" tabIndex={-1} role="dialog" aria-labelledby="searchExampleModalTitle" aria-hidden="true">
          <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="searchExampleModalTitle"></h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                {
                  lang === 'ar'?
                  <><p style={{ fontSize: 'x-large' }}>5,800,000 قطعة غيار – ابحث عن الجزء المناسب لسيارتك <br />
                        ابحث عن قطع الغيار باستخدام التركيبات التالية:</p><table className='table' style={{ fontSize: 'medium' }}>
                          <tbody>
                            <tr><th>نوع البحث</th><th>مثال</th></tr>
                            <tr><td>قطعة غيار</td><td>شمعات الاحتراق</td></tr>
                            <tr><td>قطعة غيار + مصنع القطعة</td><td>شمعات الاحتراق RIDEX</td></tr>
                            <tr><td>قطعة غيار + ماركة السيارة</td><td>شمعات الاحتراق CITROËN</td></tr>
                            <tr><td>قطعة غيار + رقم الصنف</td><td>شمعات الاحتراق 686S0071</td></tr>
                            <tr><td>رقم الصنف</td><td>13447</td></tr>
                            <tr><td>رقم الصنف + مصنع القطعة</td><td>SKSP-1990029 STARK</td></tr>
                            {/* <tr><td>رقم المعدات الأصلي (OEN)</td><td>101000003AA</td></tr>
                            <tr><td>رقم المعدات الأصلي + مصنع القطعة</td><td>101000004AA FEBI BILSTEIN</td></tr> */}
                          </tbody>
                        </table></>
                  :<><p style={{ fontSize: 'x-large' }}>5,800,000 auto parts – find the right spare part for your car <br />
                        Search for spare parts using the following combinations:</p><table className='table' style={{ fontSize: 'medium' }}>
                          <tbody>
                            <tr><th>Search type</th><th>Example</th></tr>
                            <tr><td>Car part</td><td>Spark plugs</td></tr>
                            <tr><td>Car part + car part manufacturer</td><td>Spark plugs RIDEX</td></tr>
                            <tr><td>Car part + car brand</td><td>Spark plugs CITROËN</td></tr>
                            <tr><td>Car part + item number</td><td>Spark plugs 686S0071</td></tr>
                            <tr><td>Item number</td><td>13447</td></tr>
                            <tr><td>Item number + car part manufacturer</td><td>SKSP-1990029 STARK</td></tr>
                            {/* <tr><td>OEN (original equipment number)</td><td>101000003AA</td></tr>
                            <tr><td>OEN + car part manufacturer</td><td>101000004AA FEBI BILSTEIN</td></tr> */}
                          </tbody>
                        </table></>
                }
              </div>
              
            </div>
          </div>
        </div>
        <div ref={divRef} style={{direction: dir}} className="header-icon header-search header-search-inline header-search-category w-lg-max  mt-0">
          <div className="input-group">
            <input onKeyDown={handleKeyDown} onChange={handleChange} type="text" className="form-control" style={{borderRadius: '0px',}} placeholder={t("enter_part_number_name")} aria-label={t("enter_part_number_name")} aria-describedby="basic-addon2" id="keyword_input" autoComplete='off'/>
            <div className="input-group-append">
            <span  style={{backgroundColor: '#f4f4f4',borderTop: '#f4f4f4',borderBottom: '#f4f4f4',borderRight: '#f4f4f4', color: '#a8a8a8', cursor: 'pointer'}} className="input-group-text" onClick={() => showModal()} >{t('example')} {" \u00A0  "}{" "} <i style={{fontSize: '12px'}} className={`fas fa-info-circle`}></i></span>
              <button onClick={() => onNavigateBy()} style={{
    paddingTop: 'unset', 
    paddingBottom: 'unset', 
    borderRadius: '0px',
    transition: 'background-color 0.3s ease',
    backgroundColor: isHovered ? '#0056b3' : '',
    opacity: isHovered ? 0.9 : 1
  }}   className="btn btn-primary" type="button">{t("search")} </button>
            </div>
          </div>
          <div style={{backgroundColor:'#FFF', position: 'absolute', height: 'auto', width: '100%', zIndex: 100000, display: searchResDisplay?'':'none'}}>
            {
              !loading && SearchResults.length === 0 && 
              <div className='mt-2' style={{textAlign: 'center', color: 'rgb(201, 0, 0)'}}>{t('no_result')}</div>
            }
            { !loading && 
              <table className='table table-bordered'>
                {
                  SearchResults.map((item) => {
                    return <tr style={{cursor: 'pointer'}} onClick={() => navigateHandle(item)}><td>{item.SEARCH_TITLE}</td></tr>
                  })
                }
              </table>
            }
          </div>
        </div>
        
        </>

        
      );
      
        return(
            <div style={{direction: localStorage.getItem('dir')}} className="header-icon header-search header-search-inline header-search-category w-lg-max text-right mt-0">
              
              <NavLink to={() => {}} className="search-toggle" role="button"><i className="icon-search-3" /></NavLink>
              <form action="#" method="get">
                <div className="header-search-wrapper w-100">
                  <input type="search" className="form-control text-end" name="q" id="q" value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder={`${t("search")} ...`} style={{borderRadius: 0}} required />
               
                  {/* End .select-custom */}
                  <button className="btn icon-magnifier p-0" title={t("search")} type="button" onClick={() => SearchNow()} style={{borderRadius: 0}}/>
                </div>
                {/* End .header-search-wrapper */}
              </form>
            </div>
        );
    
}

export default SearchBar;
