exports.CreateTree = async(data, PC_ID) => {
    const nodes = {};
    const rootNodes = [];

    // Create all nodes without assigning children
    await data.forEach(item => {
        const node = new Node(item.STR_ID, item.STR_NODE_NAME, item.TotalPrices);
        nodes[item.STR_ID] = node;
    });

    // Assign children to their parents
    data.forEach(item => {
        const node = nodes[item.STR_ID];
        if (item.STR_ID_PARENT === null) {
            rootNodes.push(node);
        } else {
            const parentNode = nodes[item.STR_ID_PARENT];
            try {
                if (node.TotalPrices > 0) {
                    parentNode.addChild(node);
                }
                // if(PC_ID && PC_ID !==0)
                // {
                //     if (node.TotalPrices > 0) {
                //         parentNode.addChild(node);
                //     }
                // }else{
                //     parentNode.addChild(node);
                // }
            } catch(err) {
                
            }
            
        }
    });

    // Convert the list of root nodes to a dictionary representation
    var tree = rootNodes.map(node => node.toDict());
    
    return tree;
}


class Node {
    constructor(STR_ID, STR_NODE_NAME, TotalPrices) {
        this.STR_ID = STR_ID;
        this.STR_NODE_NAME = STR_NODE_NAME;
        this.TotalPrices = TotalPrices;
        this.children = [];
    }

    addChild(childNode) {
        this.children.push(childNode);
    }

    toDict() {
        return {
            STR_ID: this.STR_ID,
            STR_NODE_NAME: this.STR_NODE_NAME,
            TotalPrices: this.TotalPrices,
            children: this.children.map(child => child.toDict())
        };
    }
}


