import { useContext, useEffect, useState } from "react";
import { GetRecents } from "../../Helper/RecentCart";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../Context/ConfigContext";
import Carousel from 'react-multi-carousel';

import axios from "axios";
import { AddToCart } from "../../Helper/MyCart";
import { ToastContainer, toast } from 'react-toastify';
import { AddToCartNotifier } from "../../Components/CartComponents";
const RecentViews = () => {
    const [recents, setRecents] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang');
    const dir = localStorage.getItem('dir');
    const {gdcURL, apiURL, currency, setCartItemsQty} = useContext(ConfigContext);
    const [loading, setLoading] = useState(true);
    
    const [responsive, setResponsive] =  useState({
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        }
      })
    useEffect(() => {
        // localStorage.setItem('recents','');
        LoadRecents();
        // var rows =  GetRecents();
        // setRecents(rows);
    }, [])
    const handleAddToCart = async(id,number, title, mrp, price,  selectQty, imgUrl, url, partImg) => {
      // toast.success('Successfully toasted!');
      
      var imgUrl = partImg ? `${gdcURL}/images/${partImg}` : `${apiURL}/public/placeholder_category.jpeg`;
      
      await AddToCart(id,number, title, mrp, price,  selectQty, imgUrl, url);
      setCartItemsQty();
      toast.success(<AddToCartNotifier item_name={title}  item_img={imgUrl}/>, {
        onClose: () => {
          
        }
      })
      // UpdateCartList(GetCarts());
      // ChangeCartProcessStatus(true);
      
    }
    const LoadRecents = () => {
        // console.log(`${gdcURL}/Parts/GetRecentViews?currency=${currency}&lang=${locale}&recents=${localStorage.getItem('recents')}`)
        setLoading(true);
        var formData = {currency: currency,lang: locale, recents:  localStorage.getItem('recents')};
        axios.post(`${gdcURL}/Orders/GetRecentViews`,formData)
        .then((response) => {
          
          setRecents(response.data.data);
          setLoading(false)
        })
        .catch((error) => {
            console.log("recentviewerror", error)
        })
        
    }
    return (
        recents && recents.length > 0?  
         <div className="container" dir={dir} style={{textAlign: dir === 'rtl'? 'right': 'left'}}>
            <h2 className="product-title">{t('recent_views')}</h2>
            <div >
            <Carousel 
                        swipeable={true}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        
                        infinite={false}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        // deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px">
            {
                recents.map((recent, index) => {
                    var partImg = recent.item_img_url?`${gdcURL}/images/${recent.item_img_url}`:`${apiURL}/public/placeholder_category.jpeg`
                   
                    return <NavLink key={`views-key-${index}`} style={{backgroundColor: 'unset'}} ><div onClick={() => {navigate(recent.url);  window.scrollTo(0, 0)}} key={`recent-${index}`} className="col-md-12">
                        <div className="card pl-5 pr-5 pb-5 pt-5">
                            <NavLink to={recent.item_url}><img onClick={() => {navigate(recent.url);  window.scrollTo(0, 0)}} style={{height: '200px'}} src={partImg} alt={recent.item_title} /></NavLink>
                            <div  className="pt-5" style={{minHeight: '130px'}}>
                              <h5 style={{
                                margin: 0,
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                color: 'red'
                              }}><NavLink to={recent.item_url}>{recent.item_title}</NavLink></h5>
                              <p>{t('article_number', {value: recent.item_number})}</p>
                              
                            </div>
                            { recent.item_price?
                            <>
                              <div className="pb-2" style={{color: '#999'}}>
                              
                              <h3 style={{lineHeight: 0}}>{recent.display_price}</h3>
                              <small>{t('price_included_vat_excluded_delivery')}</small>
                              
                            </div>
                            <button  onClick={() => {handleAddToCart(recent.item_id,recent.item_number, recent.item_title, 0, 0,  1, recent.item_img_url, recent.item_url, recent.item_img_url)}} className="btn btn-dark add-cart mr-2 text-light" ><i className="icon-shopping-cart" /> <span>{t('add_to_cart')}</span> </button>
                            </>
                              :
                              
                              <>
                              <div className="pb-2" style={{color: '#999'}}>
                              <NavLink to={recent.item_url}><h3 style={{lineHeight: 0, textAlign: 'center'}}>{t('out_of_stock')}</h3> </NavLink>
                              <small style={{visibility: 'hidden'}}>{t('price_included_vat_excluded_delivery')}</small>
                              
                            </div>

                            <NavLink to={recent.item_url}  onClick={() => { window.scrollTo(0, 0)}} className="btn btn-dark add-cart mr-2 text-light" ><i className="icon-shopping-cart" /> <span>{t('details')}</span> </NavLink>
                            </>
                              
                          }
                          
                        </div>
                    </div></NavLink>
                })
            }
            </Carousel>
            </div>
            
        </div>
       : <div></div>
            
    )
}
export default RecentViews;