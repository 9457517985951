import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ConfigContext } from "../../Context/ConfigContext";
import { useTranslation } from 'react-i18next';
import {
  CartProcess,
  DeleteCartItem,
  GetCarts,
  QtyUpdate
} from "../../Helper/MyCart";
import { Spinner } from "../../Components/Shimmers";
import { AddToCartNotifier } from "../../Components/CartComponents";

function Cart() {

  const { apiURL, gdcURL, UpdateCartList, ChangeCartStatus, CartStatus, lang, setCartItemsQty, setStateGlobleCart } = useContext(ConfigContext);

  const [cartItems, setCartItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [coupon, setCoupon] = useState(localStorage.getItem("coupon"));
  const [couponData, setCouponData] = useState([]);
  const [cartLoading, setCartLoading] = useState(true);
  const [couponsLoading, setCouponsLoading] = useState(true);
  const [shimmerCarItem, setShimmerCarItem] = useState('cart-item-');

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    setCartLoading(true);
    setCouponsLoading(true);
    fetchCartDataFromLocalStorage();
    GetCoupons();
  }, []);

  const fetchCartDataFromLocalStorage = async () => {


    // if (coupon) {
    //   localStorage.getItem("coupon");
    // }
    try {
      var carts = GetCarts();
      if (carts && Array.isArray(carts) && carts.length > 0) {
        const response = await axios.get(`${gdcURL}/Orders/GetCart`, {
          params: {
            currency: localStorage.getItem('currency') ?? 'aed',
            lang: lang,
            customer_id: localStorage.getItem("customer_id") || 0,
            carts: JSON.stringify(carts),
            coupon: coupon,
          },
        });
        const data = response.data.data;
        if (data) {
          // console.log("from cart", data);
          setCartLoading(false);
          setCouponsLoading(false);
          setCartItems(data);
          setShimmerCarItem('cart-item-');
        } else {
          console.error(data.message);
        }
      } else {
        setCartLoading(false);
        setCouponsLoading(false);
        setShimmerCarItem('cart-item-');
        setCartItems([]);
      }
    } catch (err) {
      // console.log("LoadCart err", err);
    }
  };

  const GetCoupons = async () => {

    try {
      const response = await axios.get(`${apiURL}/Home/GetCoupons?lang=en`);
      const data = response.data.data;
      if (data) {
        setCouponData(data);
      } else {
        console.log("No Coupons Found");
      }
    } catch (err) {
      console.log("err Something Went Wrong", err);
    }
  };

  const handleCouponCodeChange = (e) => {
    setCoupon(e.target.value);
  };

  const handleDeleteCartItem = async (index) => {

    const result = await MySwal.fire({
      title: "Remove Item",
      text: "Are you sure you want to remove this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes Delete",
      cancelButtonText: "Cancel!",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      DeleteCartItem(index);
      UpdateCartList(GetCarts());
      fetchCartDataFromLocalStorage();
      setShimmerCarItem('cart-item-');
      toast.success("Item Removed successfully!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      setShimmerCarItem('cart-item-');
      return;
    }
  };

  const handleQuantityChange = async (index, qty) => {
    // alert(max_qty)

    await QtyUpdate(index, qty);
    setCartItemsQty();
    fetchCartDataFromLocalStorage();
    setShimmerCarItem('cart-item-');

  };

  const RemoveCoupon = () => {
    localStorage.removeItem("coupon");
    setCoupon("");
  };

  const ApplyCoupon = async (couponCode, e) => {
    e.preventDefault();
    console.log("couponCode", couponCode)
    setCoupon(couponCode);
  };

  useEffect(() => {
    localStorage.setItem("coupon", coupon);
    setCartLoading(true);
    fetchCartDataFromLocalStorage();
    console.log("Effect triggered, coupon:", coupon);
  }, [coupon])

  const { t, i18n } = useTranslation();
  const Cart = cartItems && cartItems.items && Array.isArray(cartItems.items) && cartItems.items.length > 0;
  console.log("cartItems", localStorage.getItem('carts'));
  const dir = localStorage.getItem('dir');

  return (
    <main className="main">
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="dark"
        closeButton={false}
      />
      <div className="container">
        <ul className="checkout-progress-bar d-flex justify-content-center flex-wrap">
          <li className="active">
            <NavLink to={"/Cart"}>{t("shopping_cart")}</NavLink>
          </li>
          <li>
            <NavLink to={"/Checkout"}>{t("checkout")}</NavLink>
          </li>
          <li className="disabled">
            <NavLink to={"OrderInformation"}>{t("complete_order")}</NavLink>
          </li>
        </ul>
        {cartLoading === true ? <center><Spinner /></center> :
          cartItems.items && cartItems.items.length > 0 ? (
            <div className="row">
              <div className="col-lg-8">
                <div className="cart-table-container">
                  <table className="table table-cart">
                    <thead>
                      <tr>
                        <th className="thumbnail-col" />
                        <th className="product-col">{t("product")}</th>
                        <th className="price-col">{t("price")}</th>
                        <th className="qty-col">{t("quantity")}</th>
                        <th className="text-right">{t("sub_total")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Cart &&
                        cartItems.items.map((item, index) =>
                        (

                          <tr key={'cart-item-' + index} className="product-row">
                            <td>
                              <figure className="product-image-container">
                                <NavLink to={item.item_url} className="product-image" >
                                  <img src={item.item_img_url} alt={item.item_title} onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = "https://ghayar.ae/assets/images/prodefault.jpg";
                                  }} />
                                </NavLink>
                                <NavLink onClick={() => { setShimmerCarItem('cart-item-' + index); handleDeleteCartItem(index); }} className="btn-remove icon-cancel" title="Remove Product" />
                              </figure>
                            </td>
                            <td className="product-col">
                              <h5 className="product-title">
                                <NavLink to={item.item_url}>
                                  {item.item_title} <br />
                                  <small>{item.item_number}</small>
                                </NavLink>
                              </h5>
                            </td>
                            <td>{item.display_price}</td>
                            <td>
                              <div className="product-single-qty">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-outline btn-down-icon bootstrap-touchspin-down"
                                      type="button"
                                      // disabled={Number(item.item_qty) > 1?false: true}
                                      onClick={() => {

                                        if (Number(item.item_qty) === 1) {
                                          handleDeleteCartItem(index);
                                        } else {
                                          handleQuantityChange(index, (Number(item.item_qty) - 1))
                                        }
                                      }
                                      }
                                    ></button>
                                  </span>
                                  <input className="horizontal-quantity form-control" type="text" value={item.item_qty} readOnly />
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-outline btn-up-icon bootstrap-touchspin-up btnPlus"
                                      type="button"
                                      onClick={() => {
                                        if (Number(item.max_qty) >= (Number(item.item_qty) + 1)) {
                                          handleQuantityChange(index, (Number(item.item_qty) + 1))
                                        }else{
                                          toast.success(<AddToCartNotifier item_name={t('sorry_we_have_only', {value: item.max_qty})}  />, { })
                                        }
                                      }
                                      } >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="text-right">
                              <span className="subtotal-price">
                                {item.total_display_price}
                              </span>
                            </td>
                          </tr>
                        )
                        )

                      }
                    </tbody>


                  </table>

                </div>
              </div>

              <div className="col-lg-4">
                <div className="cart-summary">
                  <h3 className="">{"Order Summary"}</h3>
                  <div className="float-left">
                    <div className="cart-discount">
                      <form action="#">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder={t("coupon_code")}
                            value={coupon}
                            onChange={handleCouponCodeChange}
                            required
                            disabled={
                              localStorage.getItem("coupon") ? true : false
                            }
                          />
                          {localStorage.getItem("coupon") ? (
                            <div className="input-group-append">
                              <button
                                className="btn btn-sm btn-primary"
                                type="button"
                                onClick={RemoveCoupon}
                              >
                                {t("remove_coupon")}

                              </button>
                            </div>
                          ) : (
                            <div className="input-group-append">
                              <button
                                className="btn btn-sm btn-primary"
                                type="button"
                                onClick={fetchCartDataFromLocalStorage}
                              >
                                {"ApplyCoupon"}
                              </button>
                            </div>
                          )}
                        </div>
                      </form>
                      <button
                        className="d-flex justify-content-between align-items-center w-100 p-2 mt-2"
                        data-toggle="modal"
                        data-target="#CouponModel"
                        style={{
                          border: "1px solid #e7e7e7",
                          background: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/Files/Cart/copen.svg`}
                            alt=""
                          />
                          <span
                            className="ml-2"
                            style={{ color: "#cc000b", fontWeight: "500" }}
                            type="button"
                          >
                            {t("view_available_offers")}

                          </span>
                        </div>
                        <div style={{ color: "#cc000b" }}>
                          <i className="fas fa-angle-right"></i>
                        </div>
                      </button>
                      <div
                        className="modal fade"
                        id="CouponModel"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content w-75 ml-5">
                            <div className="modal-header">
                              <h5
                                className="modal-title mb-0"
                                style={{
                                  fontSize: "20px",
                                  color: "#404553",
                                  fontWeight: "medium",
                                }}
                                id="exampleModalLongTitle"
                              >
                                {t("available_offers")}
                              </h5>
                              <button
                                type="button"
                                className="close h3"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <h4>{t("coupon_offers")}</h4>

                              {couponData && couponData.length > 0 ? (
                                couponData.map((item) => (
                                  <div
                                    className="main-Featured card"
                                    style={{
                                      background: "white",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <div
                                      className="card-header d-flex align-items-center"
                                      style={{
                                        background: "white",
                                        borderRadius: "10px",
                                        borderBottom: "1px solid #dddddd",
                                        borderBottomRightRadius: "0px",
                                        borderBottomLeftRadius: "0px",
                                      }}
                                    >
                                      <img
                                        src={`${process.env.PUBLIC_URL}/assets/images/Files/Cart/coupon-discount.svg`}
                                        alt=""
                                        width={20}
                                        height={20}
                                      />{" "}
                                      <span className="ml-3">
                                        {item.coupon_title}
                                      </span>
                                    </div>
                                    <div className="p-4">

                                      <span>{item.coupon_description}</span>
                                    </div>
                                    <div className="d-flex justify-content-between  align-items-center">
                                      <h4
                                        className="text-success text-center alert-success ml-4"
                                        style={{
                                          padding: "2px 10px",
                                          border: "1px dashed rgb(40, 167, 69)",
                                        }}
                                      >
                                        {item.coupon_code}
                                      </h4>
                                      {localStorage.getItem("coupon") === item.coupon_code ? (
                                        <button className="btn btn-success Apply mr-4 mb-1">
                                          {t("applied")}
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-primary Apply mr-4 mb-1"
                                          data-dismiss="modal"
                                          onClick={(e) => ApplyCoupon(item.coupon_code, e)}
                                        >
                                          {t("apply")}
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <h1>{t("no_offers")}</h1>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {Cart && (
                    <>
                      <table className="table table-totals">
                        <tbody>
                          <tr>
                            <th>{"Product"}</th>
                            <th className="custom-dark">{"Total"}</th>
                          </tr>
                          {Cart &&
                            // ? (
                            cartItems.items.map((item) => (
                              <tr key={item.item_id}>
                                <td className="custom-light">
                                  {item.item_title} x {item.item_qty}
                                </td>
                                <td className="float-right">
                                  {item.total_display_price}
                                </td>
                              </tr>
                            ))}

                          <tr>
                            <td>{t("sub_total")}</td>
                            <td className="custom-dark">
                              <span>{cartItems.sub_total_display}</span>
                            </td>
                          </tr>

                          {localStorage.getItem("coupon") &&
                            cartItems.total_saving > 0 && (
                              <tr>
                                <td>{"Total Saving"}</td>
                                <td className="custom-dark">
                                  <span className="text-success">
                                    {" "}
                                    - {cartItems.total_saving_display}
                                  </span>
                                </td>
                              </tr>
                            )}
                          <tr style={{ border: "none", marginBottom: "0px" }}>
                            <td style={{ textAlign: dir === 'rtl' ? 'right' : 'left', }}>{t("shipping")}</td>
                            <td style={{ textAlign: dir !== 'rtl' ? 'right' : 'left', }} >
                              <span>{cartItems.display_shipping_cost}</span>
                            </td>
                          </tr>
                          {localStorage.getItem("coupon") &&
                            cartItems.total_saving > 0 && (
                              <tr>
                                <td colSpan={2} className="text-left">
                                  <div className="d-flex justify-content-center">
                                    <h4
                                      className="text-success text-center alert-success"
                                      style={{
                                        padding: "2px 10px",
                                        border: "1px dashed #28a745",
                                      }}
                                    >

                                      {t("you_will_save", { value: cartItems.total_saving_display })}
                                    </h4>
                                  </div>
                                </td>
                              </tr>
                            )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>{t("grand_total")}</td>
                            <td className="custom-dark">
                              <span>
                                {cartItems.grand_total_display}
                              </span>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                      <div className="checkout-methods">
                        <NavLink
                          to={
                            localStorage.getItem("token")
                              ? "/Checkout"
                              : "/Signup"
                          }
                          className="btn btn-block btn-dark"
                        >
                          {t("proceed_to_checkout")}
                          <i className="fa fa-arrow-right" />
                        </NavLink>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* End .col-lg-4 */}
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="cart-table-container  cart-summary">
                  <div className="d-flex justify-content-center">
                    <h5>
                      {t("your_cart_is_empty")}
                      <i className="minicart-icon"></i>
                    </h5>
                  </div>
                  <div className="checkout-methods d-flex justify-content-center">
                    <NavLink to={"/"} className="btn btn-dark btn-lg px-5">
                      {t("shop_now")}
                      <i className="fa fa-arrow-right" />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          )}

        {/* End .row */}
      </div>
      {/* End .container */}
      <div className="mb-6" />
      {/* margin */}
    </main>
  );
}

export default Cart;
