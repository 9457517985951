import React, { Component, useContext, useEffect, useState } from "react";
// import $ from 'jquery';
// import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { NavLink } from "react-router-dom";
import CarSearchBox from "../Cars/CarSeachBox";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import "./Slider.css";
const Slider = () => {
  const {apiURL, lang, currency} = useContext(ConfigContext);
  const [loading, setLoading] = useState();
  const [images, setImages] = useState([]);
  const [startX, setStartX] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const GetSliders = async () => {
    try {
      const response = await axios.get(
        `${apiURL}/Home/GetSliders?country_id=225&lang=${lang}&currency=${currency}`
      );
      console.log("GetSliders", response.data);
      if (response.data.success) {
        var imagesArray = [];
        for(var i = 0; i < response.data.data.length; i++){
          var img = response.data.data[i];
          imagesArray.push({
            original:
              "https://ghayar.ae/v1/admin/public/img/sliders/"+img.slider_image_path,
            thumbnail:
              "https://ghayar.ae/v1/admin/public/img/sliders/"+img.slider_image_path,
          });
        }
        setImages(imagesArray);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // console.error("Error fetching part details:", error);
    }
  };

  useEffect(() => {
    GetSliders();
  }, []);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    e.stopPropagation();
  };

  const handleMouseDown = (e) => {
    setStartX(e.clientX);
    e.stopPropagation();
  };

  const handleTouchEnd = (e) => {
    handleSlideEnd(e.changedTouches[0].clientX);
    e.stopPropagation();
  };

  const handleMouseUp = (e) => {
    handleSlideEnd(e.clientX);
    e.stopPropagation();
  };

  const handleSlideEnd = (endX) => {
    if (startX === null) return;
    
    const difference = startX - endX;
    const threshold = 20; // Reduced from 50 to 20 for higher sensitivity
    
    if (Math.abs(difference) > threshold) {
      // Add a speed factor based on swipe distance
      const speed = Math.abs(difference) / 100;
      const transitionDuration = Math.max(150, Math.min(300, 300 / speed));
      
      if (difference > 0) {
        // Slide left - faster transition for quicker swipes
        setCurrentIndex((prev) => 
          prev === images.length - 1 ? 0 : prev + 1
        );
      } else {
        // Slide right - faster transition for quicker swipes
        setCurrentIndex((prev) => 
          prev === 0 ? images.length - 1 : prev - 1
        );
      }
    }
    setStartX(null);
  };

  return (
    <main className="main prevent-select">
      <div className="row justify-content-center">
        <div className="col-md-3" style={{ width: '400px', flex: '0 0 400px', maxWidth: '400px'}}>
          {<CarSearchBox home={true} redirectStatus={true} />}
        </div>
        <div className="col-md-8" style={{ width: '800px', flex: '0 0 800px', maxWidth: '800px'}}>
          <div 
            className="image-gallery-wrapper"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            <ImageGallery
              showBullets={true}
              showPlayButton={false}
              showFullscreenButton={false}
              showNav={false}
              showThumbnails={false}
              originalHeight={400}
              items={images}
              infinite={true}
              slideDuration={200}
              swipingTransitionDuration={100}
              disableSwipe={true}
              preventDefaultTouchmoveEvent={true}
              useTranslate3D={false}
              slideInterval={4000}
              additionalClass="no-drag"
              startIndex={currentIndex}
              autoPlay={false}
              lazyLoad={true}
              isRTL={false}
            />
          </div>
        </div>
      </div>
      <div
        style={{ display: "none" }}
        className="home-slider slide-animate owl-carousel owl-theme show-nav-hover nav-big mb-2 text-uppercase owl-loaded owl-drag"
        data-owl-options="{ 'loop': false }">
        {/* End .home-slide */}
        <div className="owl-stage-outer">
          <div
            className="owl-stage"
            style={{
              transform: "translate3d(0px, 0px, 0px)",
              transition: "all 0s ease 0s",
              width: "2698px",
            }}>
            <div className="owl-item active" style={{ width: "1349px" }}>
              <div className="home-slide home-slide1 banner">
                <img
                  className="slide-bg"
                  src="assets/images/sliders/CSlider7.png"
                  width={1903}
                  height={499}
                  alt="slider"
                />
                <div className="container d-flex align-items-center">
                  <div
                    className="banner-layer appear-animate fadeInUpShorter animated appear-animation-visible"
                    data-animation-name="fadeInUpShorter"
                    style={{
                      animationDelay: "0ms",
                      animationDuration: "1000ms",
                    }}>
                    <h4 className="text-transform-none m-b-3">
                      {/* {t("Drivetheextramile")} */}
                      Drivetheextramile
                    </h4>
                    <h2 className="text-transform-none mb-0">
                      {/* {t("SpeedySavings")} */}
                      SpeedySavings
                    </h2>
                    <h3 className="m-b-3">{/* {t("70%Off")} */}70%Off</h3>
                    <h5 className="d-inline-block mb-0">
                      <span>{/* {t("StartingAt")} */}StartingAt</span>
                      <b className="coupon-sale-text text-white bg-secondary align-middle">
                        <sup>{/* {t("AED")} */}AED</sup>
                        <em className="align-text-top">
                          {/* {t("199")} */}199
                        </em>
                        <sup>{/* {t("99")} */}99</sup>
                      </b>
                    </h5>
                    <NavLink to={"/"} className="btn btn-lg btn-dark">
                      {/* {t("ShopNow")} */}ShopNow
                    </NavLink>
                  </div>
                  {/* End .banner-layer */}
                </div>
              </div>
            </div>
            <div className="owl-item" style={{ width: "1349px" }}>
              <div className="home-slide home-slide2 banner banner-md-vw">
                <img
                  className="slide-bg"
                  style={{ backgroundColor: "#ccc" }}
                  width={1903}
                  height={499}
                  src="assets/images/sliders/CSlider6.png"
                  alt="slider"
                />
                <div className="container d-flex align-items-center">
                  <div
                    className="banner-layer d-flex justify-content-center appear-animate"
                    data-animation-name="fadeInUpShorter"
                    style={{}}>
                    <div className="mx-auto">
                      <h4 className="m-b-1">{/* {t("Extra")} */}Extra</h4>
                      <h3 className="m-b-2">{/* {t("20%off")} */}20%off</h3>
                      <h3 className="mb-2 heading-border">
                        {/* {t("Accessories")} */}Accessories
                      </h3>
                      <h2 className="text-transform-none m-b-4">
                        {/* {t("SavingsHighway")} */}
                        SavingsHighway
                      </h2>
                      <NavLink to={"/"} className="btn btn-block btn-dark">
                        {/* {t('ShopAllSale')} */}
                        ShopAllSale
                      </NavLink>
                    </div>
                  </div>
                  {/* End .banner-layer */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="owl-nav">
          <button
            type="button"
            title="nav"
            role="presentation"
            className="owl-prev disabled">
            <i className="icon-left-open-big" />
          </button>
          <button
            type="button"
            title="nav"
            role="presentation"
            className="owl-next">
            <i className="icon-right-open-big" />
          </button>
        </div>
        <div className="owl-dots disabled" />
      </div>
      {/* End .home-slider */}

      {/* End .container */}
    </main>
  );
};

export default Slider;
