import React, { useContext, useEffect, useRef, useState } from "react";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../Context/ConfigContext";
import { useParams } from "react-router-dom";

import CarSearchBox from "../Cars/CarSeachBox";
import { Helmet } from "react-helmet";

import OtherParts from "./OtherParts";
import PopularSuppliers from "./PopularSuppliers";
import CarSelectionSection from "../Cars/CarSelectionSection";

const NoCarSelected = () => {
  const {currency} = useContext(ConfigContext);
  const location = useLocation();
  const { t } = useTranslation();
  const locale = localStorage.getItem("lang") ?? "en";
  const { gdcURL } = useContext(ConfigContext);
  const dir = localStorage.getItem("dir");
  var { slug, make, year, model, engine, fuel } = useParams();
  var STR_ID = slug.split("-").slice(-1);
  const [loading, setLoading] = useState(false);
  const [parts, setParts] = useState([]);

  const [selectedOE, setSelectedOE] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);
  
  const [departmentName, setDepartmentName] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const queryParams = new URLSearchParams(location.search);

  useState(() => {
    if(localStorage.getItem('pc_id') && localStorage.getItem('pc_id') !=0 && localStorage.getItem('pc_id')!=null){
      var nocar = window.location.href.replaceAll('Spare-Parts-nocar', 'Spare-Parts');
      window.location = nocar
    }
  }, [])
  useEffect(() => {
    setDepartmentName(slug.split("-").slice(0, -1).join(" "));
    const supplier = queryParams.get('supplier');
    if(supplier){
      var sup_name = supplier.split('-');
      if(sup_name){
        setSupplierName(sup_name[1])
      }
      console.log("supplierName", supplierName)
    }
   
  }, [])
  const [car_info, setCarInfo] = useState(
    localStorage.getItem("car_info")
      ? JSON.parse(localStorage.getItem("car_info"))
      : []
  );
  const [loadMore, setLoadMore] = useState(false);
  const loadMoreRef = useRef(null); 
  
  const navigate = useNavigate();
  return (
    <main className="main" dir={dir}>
      <Helmet>
          <title>{departmentName}</title>
          <meta name="keywords" content={localStorage.getItem("pc_id") && loading === false &&
                   
                   parts && parts.length > 0 && ((selectedBrands.length !== 0 && selectedOE === true) ||
                      selectedOE !== true) && 
                    parts.map((part, index) => {
                      return `${part.PRODUCT_GROUP} - ${part.ART_ARTICLE_NR} - ${part.ART_SUP_BRAND}`
                    })
            } />
      </Helmet>
      <div className="row pl-2">
        <div className="col-md-12">
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to={"/"}>
                    <i className="icon-home" />
                  </NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to={`/Spare-Parts/Catelog`}>{t("catelog")}</NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {departmentName}
                </li>
              </ol>
            </nav>
        </div>
        <div className="col-md-12">
            <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                {t('for_my_car', {value: departmentName})}
            </h2>
        </div>
        
        <div className="row pt-3" style={{backgroundColor: 'rgb(238, 238, 238)'}}>
            <div className="col-md-5">
            <CarSearchBox defaultMake={make&&make.split('-')[1]} defaultYear={year} defaultModel={model&&model.split('-')[1]} defaultEngine={engine&&engine.split('-')[1]} redirectStatus={true} />
            
            
            </div>
            <div className="col-md-6" >
                <center>
                    <br /> <br />
                    <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                        {t('where_to_buy_at_good', {value: departmentName})}
                    </h2>
                    <img
                    src={`${gdcURL}/category-images/600x600/${STR_ID}`}
                    className="img-fluid"
                    width={400}
                    alt={departmentName}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // Prevents infinite loop in case fallback also fails
                        currentTarget.src = "https://placehold.co/100x100/FFFFFF/FFFFFF/png";
                    }}
                    />
                </center>
            </div>
        </div>
        <div className="col-md-12 mt-3">
          <CarSelectionSection brand={supplierName} department={departmentName} year={year} model={model} engine={engine} fuel={fuel} make={make} base_url={`/Spare-Parts/${slug}`}/>
        </div>
        {STR_ID && <div className="col-md-12 mt-3">
            <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                {t('advantageous_offers_for', {value: departmentName})}
            </h2>
            <OtherParts STR_ID={STR_ID}/>
        </div>}

        <div className="col-md-12 mt-3 mb-5">
            <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                {t('best_brands_original', {value: departmentName})}
            </h2>
            <PopularSuppliers />
        </div>
        
        
      </div>
    </main>
  );
};
const ImageWithFallback = ({ src, index, alt, fallbackText, OE }) => {
  const [imgError, setImgError] = useState(false);

  const handleError = () => {
    setImgError(true);
  };
  const fallbackStyle = {
    textAlign: "center",
    fontSize: "10px",
    padding: "10px",
    wordBreak: "break-word",
    maxWidth: "90%",
  };

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <div style={containerStyle}>
      {imgError ? (
        <div style={fallbackStyle}>{fallbackText}</div>
      ) : (
        <img
          draggable="false"
          style={{
            height: "40px",
            margin: "auto",
            float: "none",
            marginTop: OE === false ? "5px" : 0,
          }}
          key={`img-part-${index}`}
          src={src}
          alt={alt}
          onError={handleError}
        />
      )}
    </div>
  );
};
export default NoCarSelected;
