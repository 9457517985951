import { Component, useContext, useEffect, useState } from "react";
import {NavLink, useNavigate} from 'react-router-dom';
import { ConfigContext } from "../../Context/ConfigContext";
// import { AddToCart, GetCarts } from "../../Helper/Carts";
import { AddToCart, GetCartItemQty, GetCarts } from "../../Helper/MyCart";
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation, withTranslation } from 'react-i18next';
import { AddToCartNotifier } from "../CartComponents";

const PartsHorizontalCard = ({partImg,  id,number, title, mrp, price,  qty, imgUrl, url, criteria, displayPrice, brand, supid, partInfo, display_price, pt_intl, suplogo}) => {
      
      const {local, apiURL, gdcURL, setCartItemsQty, isUserInternational} = useContext(ConfigContext);
      const [brandImg, setBrandImg] = useState(true);
      const { t } = useTranslation();
      const navigate = useNavigate();
      const dir = localStorage.getItem('dir');
      const [add_to_cart_status, setAdd_to_cart_status] = useState(false);
      const [selectQty, setSelectedQty] = useState(1);
      const [PTINTL, setPTINTL] = useState(pt_intl);
      
      // var partImg = partImg?`${gdcURL}/images/${partImg}`:`${apiURL}/public/placeholder_category.jpeg`
      var partURL = url; 
      useEffect(() => {
        if(!isUserInternational){
          setPTINTL(1) 
        }
      }, [])
      const removeBrandImage = () => {
        setBrandImg(false);
      }
      const handleAddToCart = async(slug) => {
        var prev_qty = await GetCartItemQty(number);
        if(Number(qty) >= Number(selectQty)+prev_qty){
          // setSelectedQty(prevQty => prevQty + 1);
        
        // toast.success('Successfully toasted!');
        
        setAdd_to_cart_status(true)
        // console.log(this.props.id,this.props.number, this.props.title, this.props.mrp, this.props.price,  this.props.qty, this.props.imgUrl, this.props.url)
        // console.log(id,number, title, mrp, price,  selectQty, imgUrl, url);
        
        await AddToCart(id,number, title, mrp, price,  selectQty, imgUrl, url, qty);
        setCartItemsQty();
        
        
        toast.success(<AddToCartNotifier item_name={title}  item_img={imgUrl}/>, {
          onClose: () => {
            setAdd_to_cart_status(false)
          }
        })
      }else{
        toast.success(<AddToCartNotifier item_name={t('sorry_we_have_only', {value: qty})}  item_img={imgUrl} />, { })
      }
        // UpdateCartList(GetCarts());
        // ChangeCartProcessStatus(true);
        
      }
      const incrementQty = async() => {
        
        var prev_qty = await GetCartItemQty(number);
        if(Number(qty) > Number(selectQty)+prev_qty){
          setSelectedQty(prevQty => prevQty + 1);
        }else{
          toast.success(<AddToCartNotifier item_name={t('sorry_we_have_only', {value: qty})}  item_img={imgUrl} />, { })
        }
       
      };
    
      const decrementQty = () => {
        setSelectedQty(prevQty => (prevQty > 1 ? prevQty - 1 : 1)); // Assuming quantity can't be less than 1
      };
      return <div className="col-sm-12 col-6 product-default left-details product-list mb-2" style={{borderBottom: '1px solid #CCC'}}>
      
      <figure style={{padding: '30px', marginBottom: 'auto'}}>
        
        <a href={partURL}>
          <img src={partImg} width={300} style={{width: '300px', marginTop: '40px', border: '1px solid #CCC'}} height={300} alt="product" />
          {/* <img src={partImg} width={250} style={{width: '250px', border: '1px solid #666'}} height={250} alt="product" /> */}
        </a>
      </figure>

      <ImageWithFallback
            src={`${gdcURL}/sup-logo/${suplogo}`}
            alt={supid}
            index={supid}
            fallbackText={suplogo}
            OE={false}
          />
      <div className="product-details responsive-width-part-desc" >
        <div className="category-list">
          <NavLink to={partURL} className="product-category">{number}</NavLink>
        </div>
        <h3 className="product-title"> <NavLink to={partURL}>{title} - {number} - {brand}</NavLink>
        </h3>
        <div className="ratings-container">
          <span className="mt-1">{t('article_number', {value: number})}</span>
      
          <div className="product-ratings mr-3 ml-3">
            <span className="ratings" style={{width: '100%'}} />
            {/* End .ratings */}
            <span className="tooltiptext tooltip-top">5.00</span>
          </div>
          {/* End .product-ratings */}
        </div>
        {/* End .product-container */}
        <p className="product-description w-100 pr-5">
          
        {
              criteria && <table className="table table-striped w-100 mr-4" dir={dir} style={{textAlign: dir === 'rtl'? 'right': 'left'}}>
              <tbody>
              {
                  criteria.split(';').map((item, indixSub) => {
                      var crRow = item.split(':');
                      return <tr key={`info-tr-${indixSub}`}><td><span>{crRow[0]}</span></td><td><span>{crRow[1]}</span></td></tr>
                  })
              }
              </tbody>
              <tfoot><td colSpan={2}><NavLink to={partURL} className="btn btn-outline-primary" style={{textTransform: 'capitalize'}}>{t('details')} <i className="fas fa-long-arrow-alt-right"></i></NavLink></td></tfoot>
              </table>
            }
            
          </p>
        
        {/* End .price-box */}
        
      </div>
      <div className="product-action" style={{marginBottom: 'auto'}}>
      <div className="price-box">
      <div className='row justify-content-center'>
          <div className='col-md-12 mb-2'>
            {price > 0 && <span ><i style={{color: 'rgb(11, 207, 28)'}} className="far fa-dot-circle"></i> {t('in_stock')}</span>}
            
            {price <= 0 && <h1 className='mt-4' >{t('out_of_stock')}</h1>}
            
            {price > 0 && <h1 className='mt-4' style={{lineHeight: 1}}>{display_price}</h1>}
            {price > 0 &&  <small>{t('price_included_vat_excluded_delivery')}</small>}
            
            {PTINTL === 0 && 
              <div className='pl-5 pr-5 pt-3 pb-1' style={{backgroundColor: 'rgb(254, 245, 245)'}}>
                <div style={{ color: 'rgb(226, 10, 24)'}}><i className="fas fa-globe"></i> {t('international_delivery_not_for', {value:''})} </div>
                <div style={{ color: 'rgb(254, 245, 245)'}}><h3 style={{fontWeight:400}}> {title}</h3></div>
              </div>
              }
            {/* {PTINTL === 0 && <span className='alert alert-danger mt-2' >{t('international_delivery_not_for', {value: title})}</span>} */}
          </div>
          {PTINTL > 0 && price > 0 && 
          <div className="col-md-4" style={{width: '100%'}}>
            <div className="input-group mb-3 float-right">
              <div className="input-group-prepend" onClick={decrementQty}>
                <span className="input-group-text"><i className="fas fa-minus"></i></span>
              </div>
              <div type="number"  className="form-control no-arrows" value="1" style={{textAlign: 'center', lineHeight: '35px'}} aria-label="Amount (to the nearest dollar)" >{selectQty}</div>
              <div className="input-group-append" onClick={incrementQty}>
                <span className="input-group-text"><i className="fas fa-plus"></i></span>
              </div>
            </div>
          </div>}
          <div className="col-md-7">
          {PTINTL > 0 && price > 0 ? 
            <button onClick={() => handleAddToCart()  } className="btn btn-dark add-cart mr-2 text-light " title={t('add_to_cart')} >
              {t('add_to_cart')}
            </button>
            :<button style={{visibility: 'hidden'}} disabled className="btn btn-dark add-cart mr-2 text-light " title={t('add_to_cart')} >
            {t('add_to_cart')}
          </button>}
            
          </div>
        </div>
         
        </div>
          
        </div>
      {/* End .product-details */}
    </div>
}
const ImageWithFallback = ({ src, index, alt, fallbackText, OE }) => {
  const [imgError, setImgError] = useState(false);

  const handleError = () => {
    setImgError(true);
  };
  const fallbackStyle = {
    textAlign: "center",
    fontSize: "10px",
    padding: "10px",
    wordBreak: "break-word",
    maxWidth: "90%",
  };

  const containerStyle = {
    position: "absolute",
    width: "210px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top:0,
  };
  return (
    <div style={containerStyle}>
      {imgError ? (
        <div style={fallbackStyle}>{fallbackText}</div>
      ) : (
        <img
          draggable="false"
          style={{
            height: "50px",
            margin: "auto",
            float: "none",
            marginTop: OE === false ? "5px" : 0,
          }}
          key={`img-part-${index}`}
          src={src}
          alt={alt}
          onError={handleError}
        />
      )}
    </div>
  );
  
};

export default PartsHorizontalCard;