import { t } from "i18next";
import { useState, useContext, useEffect  } from "react"; 
import { useTranslation } from "react-i18next";
import axios from "axios";

const { NavLink, useLocation } = require("react-router-dom")
const { ConfigContext } = require("../../Context/ConfigContext")

const Contact = ({page}) => {
    const {apiURL, token, lang} = useContext(ConfigContext);
    const { t } = useTranslation();
    const dir = localStorage.getItem('dir');

    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location])
    return (
          <div dir={dir} style={{textAlign: dir === 'ltr'?'left': 'right'}}>
          
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={() => {}}><i className="icon-home" /></NavLink></li>
                <li className="breadcrumb-item active" aria-current="page">{t('contact_us')}</li>
              </ol>
            </div>{/* End .container */}
          </nav>
          <div className="about-section">
          
            <div className="row justify-content-center">
              <div className="col-8">
                <h2>{t('contact_us')} </h2>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14417.852513729862!2d55.50450725095995!3d25.38930364883116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef5f7003a73470d%3A0xecac66590a6aa1f2!2sGhayar%20Auto%20Spare%20Parts%20-%20Head%20Office!5e0!3m2!1sen!2sin!4v1734262982025!5m2!1sen!2sin" width="600" height="450" style={{border:0, width: '100%'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

              </div>
              <div className="col-8">
                {/* <h2 className="ls-n-25 m-b-1"> Contact Info </h2> */}
                <br />
                <p> {t("we_are_to_help")} </p>
              </div>
              <div className="col-8">
              <div className="row justify-content-center">
                    <div className="col-sm-6 col-lg-3" >
                        <div className="feature-box text-center">
                            <i className="sicon-location-pin"></i>
                            <div className="feature-box-content">
                                <h3>Address</h3>
                                <h5>{t('head_office_address')}</h5>
                                <h5>{t('brand_1_address')}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3" >
                        <div className="feature-box text-center">
                            <i className="fa fa-mobile-alt"></i>
                            <div className="feature-box-content">
                                <h3>{t('phone_number')}</h3>
                                <h5>(+971) 800 442522</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3" >
                        <div className="feature-box text-center">
                            <i className="far fa-envelope"></i>
                            <div className="feature-box-content">
                                <h3>{t('email_address')}</h3>
                                <h5>contact@ghayar.ae</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3" >
                        <div className="feature-box text-center">
                            <i className="far fa-calendar-alt"></i>
                            <div className="feature-box-content">
                                <h3>{t('working_hours')}</h3>
                                <h5>8:00AM-7:00 PM</h5>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              
            </div>
            <div className="contact-info">
            
            </div>

           
           
          </div>
         
        </div>
          )
}

export default Contact;