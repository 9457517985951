import Autocomplete from "react-google-autocomplete";
import React, { useState, useEffect, useContext } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import AccountSiderBar from "./AccountSideBar";
import { useTranslation } from "react-i18next";
import Select from 'react-select'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const ManageAddress = () => {
  const { t } = useTranslation();
  const { apiURL, token } = useContext(ConfigContext);
  const dir = localStorage.getItem("dir");
  const { address_id } = useParams();
  // Individual states for each form element
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [country, setCountry] = useState("");
  const [completeAddress, setCompleteAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [mobileNumber1, setMobileNumber1] = useState("");
  const [mobileNumber2, setMobileNumber2] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [selected, setSelected] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [countryId, setCountryId] = useState(0);
  const [addressLat, setAddressLat] = useState("");
  const [addressLng, setAddressLng] = useState("");
  const [addressId, setAddressId] = useState("");

  const [countries, setCountries] = useState([]);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [CountryAlphaCode, setCountryAlphaCode] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    GetCountries();
    if (address_id) {
      GetAddresseInfo();
    }

    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       const currentLocation = {
    //         lat: position.coords.latitude,
    //         lng: position.coords.longitude
    //       };
    //       setMapCenter(currentLocation);
          
    //       // Optionally: Reverse geocode to get address details
    //       const geocoder = new window.google.maps.Geocoder();
    //       geocoder.geocode({ location: currentLocation }, (results, status) => {
    //         if (status === "OK" && results[0]) {
    //           setCompleteAddress(results[0].formatted_address);
    //           handlePlaceSelected(results[0]);
    //         }
    //       });
    //     },
    //     (error) => {
    //       console.log("Error getting location:", error);
    //       // Fallback to Dubai coordinates if location access is denied
    //       setMapCenter({ lat: 25.2048, lng: 55.2708 });
    //     }
    //   );
    // } else {
    //   // Fallback for browsers that don't support geolocation
    //   setMapCenter({ lat: 25.2048, lng: 55.2708 });
    // }
    // console.log("address_id", address_id === undefined ? 'not define' : 'found')
  }, []);
  const [isMapReady, setIsMapReady] = useState(false);
  useEffect(() => {
    const initializeMap = () => {
      if (!window.google) {
        setTimeout(initializeMap, 100);
        return;
      }

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const currentLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            setMapCenter(currentLocation);
            setIsMapReady(true);
            
            // Only try to geocode after confirming Google Maps is loaded
            if (window.google && window.google.maps) {
              const geocoder = new window.google.maps.Geocoder();
              geocoder.geocode({ location: currentLocation }, (results, status) => {
                if (status === "OK" && results[0]) {
                  setCompleteAddress(results[0].formatted_address);
                  handlePlaceSelected(results[0]);
                }
              });
            }
          },
          (error) => {
            console.log("Error getting location:", error);
            setMapCenter({ lat: 25.2048, lng: 55.2708 });
            setIsMapReady(true);
          }
        );
      } else {
        setMapCenter({ lat: 25.2048, lng: 55.2708 });
        setIsMapReady(true);
      }
    };

    initializeMap();
  }, []);
  const GetAddresseInfo = async () => {
    try {
      const customer_id = localStorage.getItem("customer_id");
      const headers = {
        token: token,
        Accept: "application/json",
        "Content-Type": "application/json"
      };

      const response = await axios.get(
        `${apiURL}/Account/GetAddresses?customer_id=${customer_id}&address_id=${address_id}`,
        { headers }
      );

      const data = response.data;

      if (data.success) {
        var row = data.data[0];
        console.log("row", row)
        setFirstName(row.first_name);
        setLastName(row.last_name);
        setCompanyName(row.company_name);
        setCountry(row.country);
        setCompleteAddress(row.complete_address);
        setApartment(row.apartment);
        setCity(row.city);
        setState(row.state);
        setPincode(row.pincode);
        setMobileNumber1(row.mobile_number_1);
        setMobileNumber2(row.mobile_number_2);
        setContactEmail(row.contact_email);
        setSelected(row.selected === 1);
        setCountryCode(row.country_code);
        setCountryId(row.country_id);
        setAddressLat(row.address_lat);
        setAddressLng(row.address_lng);
        setAddressId(row.address_id);
      } else {
        console.log("Error While Fetching Customer Addresses", data.message);
      }
    } catch (error) {
      console.log("Oops Something Went Wrong", error);
    }
  };
  const GetCountries = async () => {
    try {
      const response = await axios.get(`${apiURL}/Home/GetCountries`);
      const data = response.data;
      if (data) {
        setCountries(data.data);
      } else {
        console.log("No Countries Found", response.data.message);
      }
    } catch (err) {
      console.log("Something Went Wrong", err);
    }
  };

  const handleCountryChange = (e) => {
    const countryValue = e.target.value;
    const currentCountry = countries.find(
      (country) => country.country === countryValue
    );

    setCountry(countryValue);
    if (currentCountry) {
      setCountryId(currentCountry.country_id);
      setCountryCode(currentCountry.country_alpha1_code);
    }
  };

  const AddUpdateCustomerAddress = async (e) => {
    e.preventDefault();
    setSaving(true);
    if (!pincode || pincode === '') {
      Swal.fire({
        title: t('error'),
        text: t('enter_zip_code'),
        icon: "error",
        confirmButtonText: "Okay",
      });
      setSaving(false);
      return false;
    } else if (countryId !== 1 && pincode < 1) {
      Swal.fire({
        title: t('error'),
        text: t('enter_zip_code'),
        icon: "error",
        confirmButtonText: "Okay",
      });
      setSaving(false);
      return false;
    } else {

    }
    try {
      const headers = {
        token: token,
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const addressData = {
        customer_id: localStorage.getItem("customer_id"),
        first_name: firstName,
        last_name: lastName,
        company_name: companyName,
        country: country,
        complete_address: completeAddress,
        apartment: apartment,
        city: city,
        state: state,
        pincode: pincode,
        mobile_number_1: mobileNumber1,
        mobile_number_2: mobileNumber2,
        contact_email: contactEmail,
        selected: selected ? 1 : 0,
        country_code: countryCode,
        country_id: countryId,
        address_lat: "address_lat",
        address_lng: "address_lng",
      };
      if (address_id) {
        addressData.address_id = address_id;
      }
      const response = await axios.post(
        `${apiURL}/Account/AddAddress`,
        addressData,
        { headers }
      );

      if (response.data.success) {
        Swal.fire({
          title: "Successfully",
          text: response.data.message,
          icon: "success",
          confirmButtonText: "Okay",
        });
        if (!addressId) {
          resetFields();
        }
        navigate('/MyAccount/Addresses')
      } else {
        Swal.fire({
          title: "Error",
          text: response.data.message,
          icon: "error",
          confirmButtonText: "Okay",
        });
      }
      setSaving(false);
    } catch (error) {
      setSaving(false);
      setErrorMessage("An error occurred Adding/Updating Address.");
    }
  };

  const resetFields = () => {
    setFirstName("");
    setLastName("");
    setCompanyName("");
    setCountry("");
    setCompleteAddress("");
    setApartment("");
    setCity("");
    setState("");
    setPincode("");
    setMobileNumber1("");
    setMobileNumber2("");
    setContactEmail("");
    setSelected(false);
    setCountryCode("");
    setCountryId(0);
    setAddressLat("");
    setAddressLng("");
    setAddressId("");
  };

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
    marginBottom: '20px'
  };


  const [mapCenter, setMapCenter] = useState({ lat: 25.2048, lng: 55.2708 }); // Dubai coordinates
  const [libraries] = useState(['places']); // Add this

  // Update the handlePlaceSelected function
  const handlePlaceSelected = (place) => {
    if (place.geometry) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      setAddressLat(lat);
      setAddressLng(lng);
      setMapCenter({ lat, lng });
      setCompleteAddress(place.formatted_address);

      // Parse address components
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'postal_code':
            setPincode(component.long_name);
            break;

          case 'locality':
            setCity(component.long_name);
            break;

          case 'administrative_area_level_1':
            setState(component.long_name);
            break;

          case 'country':
            const selectedCountry = countries.find(
              c => c.country.toLowerCase() === component.long_name.toLowerCase()
            );
            if (selectedCountry) {
              setCountry(selectedCountry.country);
              setCountryId(selectedCountry.country_id);
              setCountryCode(selectedCountry.phone_code);
            }
            break;
        }
      }
    }
  };

  const searchBoxStyle = {
    position: 'absolute',
    top: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80%',
    maxWidth: '400px',
    zIndex: 1
  };
  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    
    setAddressLat(lat);
    setAddressLng(lng);
    setMapCenter({ lat, lng });
  
    // Get address from coordinates (reverse geocoding)
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results[0]) {
        setCompleteAddress(results[0].formatted_address);
        
        // Parse address components like before
        for (const component of results[0].address_components) {
          const componentType = component.types[0];
  
          switch (componentType) {
            case 'postal_code':
              setPincode(component.long_name);
              break;
            case 'locality':
              setCity(component.long_name);
              break;
            case 'administrative_area_level_1':
              setState(component.long_name);
              break;
            case 'country':
              const selectedCountry = countries.find(
                c => c.country.toLowerCase() === component.long_name.toLowerCase()
              );
              if (selectedCountry) {
                setCountry(selectedCountry.country);
                setCountryId(selectedCountry.country_id);
                setCountryCode(selectedCountry.phone_code);
              }
              break;
          }
        }
      }
    });
  };
  return (
    <main>
      <div className="page-header">
        <div className="container d-flex flex-column align-items-center">
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb" dir={dir}>
                <li className="breadcrumb-item">
                  <NavLink to={"/"}>{t("home")}</NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to={'/MyAccount'}>{t("my_account")}</NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to={'/MyAccount/Addresses'}>{t("addresses")}</NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t("manage_address")}
                </li>
              </ol>
            </div>
          </nav>
          <h1>{t("manage_address")}</h1>
        </div>
      </div>
      <div
        className={`container account-container custom-account-container `}
        dir={dir}
      >
        <div className="row">
          <AccountSiderBar />
          <div
            className={`col-lg-9 order-lg-last order-1 tab-content ${dir === "rtl" ? "text-right" : ""
              }`}
            dir={dir}
          >
            <div className="tab-pane fade show active" id="address" role="tabpanel">
              <div className="address account-content mt-0 pt-2">
                <h4 className="title">
                  <NavLink
                    to={`/MyAccount/Addresses`}
                    className={`link-to-tab ${dir === "rtl" ? "text-right" : ""}`}
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <i className="icon-left-open"></i> {t("back_to_address")}
                  </NavLink>
                </h4>
                <form className="mb-2">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {t("first_name")} <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {t("last_name")} <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          {t("phone")} <span className="required">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={mobileNumber1}
                          onChange={(e) => setMobileNumber1(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          {t("email")} <span className="required">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          value={contactEmail}
                          onChange={(e) => setContactEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{t("company")}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                  <div className="select-custom">
                    <label>
                      {t("country")} <span className="required">*</span>
                    </label>
                    {
                      countries &&
                      <Select
                        value={countries.find(country => country.country_id === countryId) || null}
                        options={countries}
                        onChange={(item) => {
                          setCountryId(item.country_id);
                          setCountryCode(item.phone_code);
                          setCountry(item.country);
                          setCountryAlphaCode(item.country_alpha1_code);
                          if (item.country_id === 1) {
                            setPincode('000000')
                          } else {
                            setPincode('')
                          }


                        }}
                      />
                    }
                  </div>
                  <div className="form-group">
                    <label>{t("location")}</label>
                    
                    <LoadScript
                      googleMapsApiKey="AIzaSyCnDyGNIfJgUAA3evE-n805f1u16Doq6Qs"
                      libraries={['places']}
                    >
                      <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={mapCenter}
                        zoom={15} 
                        onClick={handleMapClick} 
                      >
                        <div style={searchBoxStyle}>
                          <Autocomplete
                            className="form-control"
                            onPlaceSelected={handlePlaceSelected}
                            placeholder={t("search_location")}
                            options={{
                              types: ['address'],
                              componentRestrictions: { country: CountryAlphaCode }
                            }}
                            style={{ width: '100%', height: '40px', paddingLeft: '16px' }}
                          />
                        </div>
                        <Marker position={mapCenter} />
                        <i 
                          className="fas fa-crosshairs"
                          style={{
                            position: "absolute",
                            top: "150px",
                            right: "10px",
                            fontSize: "24px",
                            color: "#333",
                            cursor: "pointer",
                            zIndex: 1000,
                            padding: "8px",
                            backgroundColor: "white",
                            borderRadius: "4px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
                          }}
                          onClick={() => {
                            if (navigator.geolocation) {
                              navigator.geolocation.getCurrentPosition(
                                (position) => {
                                  const currentLocation = {
                                    lat: position.coords.latitude,
                                    lng: position.coords.longitude
                                  };
                                  setMapCenter(currentLocation);
                                  
                                  const geocoder = new window.google.maps.Geocoder();
                                  geocoder.geocode({ location: currentLocation }, (results, status) => {
                                    if (status === "OK" && results[0]) {
                                      setCompleteAddress(results[0].formatted_address);
                                      handlePlaceSelected(results[0]);
                                    }
                                  });
                                },
                                (error) => {
                                  console.log("Error getting location:", error);
                                }
                              );
                            }
                          }}
                        />
                      </GoogleMap>
                    </LoadScript>
                  </div>

                  <div className="form-group">
                    <label>{t("address")}</label>
                    <textarea
                      type="text"
                      className="form-control"
                      value={completeAddress}
                      onChange={(e) => setCompleteAddress(e.target.value)}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label>{t("town_city")}</label>
                    <Autocomplete
                      apiKey={`AIzaSyCnDyGNIfJgUAA3evE-n805f1u16Doq6Qs`}
                      className="form-control"
                      value={city}
                      onChange={(e) => setCity(e.value)}
                      onPlaceSelected={(place) => {

                        var comps = place.address_components;
                        for (var i = 0; i < comps.length; i++) {
                          console.log("Types", comps)
                          if (comps[i].types[0] === 'locality') {
                            setCity(comps[i].long_name);
                          }
                          if (comps[i].types[0] === 'administrative_area_level_1') {
                            setState(comps[i].long_name);
                          }
                          if (comps[i].types[0] === 'country') {

                          }
                        }
                      }}
                    />
                  </div>
                  

                  <div className="form-group">
                    <label>{t("zipcode")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="pincode"
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      required
                    />
                  </div>

                  <div className="form-footer mb-0">
                    <div className="form-footer-right d-flex justify-content-between">
                      <span>
                        {errorMessage && (
                          <div id="error" className="alert alert-danger">
                            {errorMessage}
                          </div>
                        )}
                        {successMessage && (
                          <div id="success" className="alert alert-success">
                            {successMessage}
                          </div>
                        )}
                      </span>

                      {

                        saving ? (
                          <button type="button" className="btn btn-default">
                            {t("Saving Address...")}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={AddUpdateCustomerAddress}
                          >
                            {t("save_address")}
                          </button>
                        )}
                    </div>
                  </div>
                  {/* Additional fields and buttons */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ManageAddress;



