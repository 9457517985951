import { useTranslation } from 'react-i18next';

const AddressCard = ({item, onDataChanged}) =>{
    const { t } = useTranslation();
    return <div>{t('')}</div>
};

const AddToCartNotifier = ({item_name, item_img}) => {
    return <div>
        <div className='row'>
            {item_img && <div className='col-md-2'><img src={item_img} alt={item_name}/></div>}
            <div className='col-md-10'>{item_name}</div>
        </div>
    </div>
}

export {AddressCard, AddToCartNotifier};